/* @app-components/UserOrg.js */
.hide-border-bottom {
  border-bottom: none !important;
}

/* @app-components/MultiEventWidget.js */
.multi-event-widget .slick-dots li {
  background: #8e8e8e;
}
.multi-event-widget .slick-dots li.slick-active button {
  background: black !important;
}

/* @app-components/UserEvent.js */
.ticket-row {
  vertical-align: top;
}

/* app-components/SiteSearch.js */
#site-wide-search .ant-select-selection__placeholder {
  padding-left: 20px;
}
#site-wide-search .ant-input.ant-select-search__field {
  padding-left: 32px;
}

/* @app-components/Notificationindicator.js */
#notificationPopover .ant-list-item:hover {
  background: #e6f7ff;
}
#notificationPopover .ant-popover-inner-content {
  padding: 0;
}
.shake-animation {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 20s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  0.1% {
    transform: rotate(10deg);
  }
  0.2% {
    transform: rotate(20deg);
  }
  0.3% {
    transform: rotate(30deg);
  }
  0.4% {
    transform: rotate(20deg);
  }
  0.5% {
    transform: rotate(10deg);
  }
  0.6% {
    transform: rotate(0deg);
  }
  0.7% {
    transform: rotate(-10deg);
  }
  0.8% {
    transform: rotate(-20deg);
  }
  0.9% {
    transform: rotate(-30deg);
  }
  1.0% {
    transform: rotate(-20deg);
  }
  1.1% {
    transform: rotate(-10deg);
  }
  1.2% {
    transform: rotate(0deg);
  }
  1.3% {
    transform: rotate(10deg);
  }
  1.4% {
    transform: rotate(20deg);
  }
  1.5% {
    transform: rotate(30deg);
  }
  1.6% {
    transform: rotate(20deg);
  }
  1.7% {
    transform: rotate(10deg);
  }
  1.8% {
    transform: rotate(0deg);
  }
  1.9% {
    transform: rotate(-10deg);
  }
  2.0% {
    transform: rotate(-20deg);
  }
  2.1% {
    transform: rotate(-30deg);
  }
  2.2% {
    transform: rotate(-20deg);
  }
  2.3% {
    transform: rotate(-10deg);
  }
  2.4% {
    transform: rotate(0deg);
  }
  2.5% {
    transform: rotate(10deg);
  }
  2.6% {
    transform: rotate(20deg);
  }
  2.7% {
    transform: rotate(30deg);
  }
  2.8% {
    transform: rotate(20deg);
  }
  2.9% {
    transform: rotate(10deg);
  }
  3.0% {
    transform: rotate(0deg);
  }
  3.1% {
    transform: rotate(-10deg);
  }
  3.2% {
    transform: rotate(-20deg);
  }
  3.3% {
    transform: rotate(-30deg);
  }
  3.4% {
    transform: rotate(-20deg);
  }
  3.5% {
    transform: rotate(-10deg);
  }
  3.6% {
    transform: rotate(0deg);
  }
}

/* @app-components/OrgEmailForm */
@media screen and (max-width: 400px) {
  .email-container .two-column .column,
  .email-container .three-column .column {
    max-width: 100% !important;
  }
  .email-container .two-column img {
    max-width: 100% !important;
  }
  .email-container .three-column img {
    max-width: 50% !important;
  }
  .bulletproofButton,
  .bulletproofButton a {
    width: 100%;
  }
}
@media screen and (min-width: 401px) and (max-width: 620px) {
  .email-container .three-column .column {
    max-width: 33% !important;
  }
  .email-container .two-column .column {
    max-width: 50% !important;
  }
}

/* @app-components/UserOrgDonate */
.suggested-donation-amounts .ant-radio-wrapper > span:not(.ant-radio) {
  width: 100%;
}

/* @app-components/TicketDownload*/
@media print {
  .event-ticket-text {
    color: black !important;
  }
  .event-ticket-content-wrapper {
    background: white !important;
  }
}

@media screen and (max-width: 599px) {
  .event-ticket-wrapper {
    width: 100% !important;
    height: auto !important;
  }
  .event-ticket-content-wrapper,
  .event-ticket-content,
  .event-ticket-data-wrapper {
    width: 100% !important;
  }
}

/* @components/HomeSplash.js */
.smorgborg-row,
.ant-table-tbody > tr.smorgborg-row:hover > td {
  background: #008000;
  color: #ffffff;
}

/* @components/MessageList */

.child-message .child-message-timestamp {
  opacity: 0;
}

.child-message:hover .child-message-timestamp {
  opacity: 1;
}

/* @app-components/AdminPlatformInvitationList.js */
.hide-expand-icon .ant-table-row-expand-icon {
  display: none;
}

/* @components/MessageList */
#messageList .linkified {
  word-break: break-all;
}

/* @app-components/MessageRoomHeader */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-enter {
  transform: translateX(-100vw);
  opacity: 0;
  position: absolute;
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit.slide-exit-active {
  transform: translateX(100vw);
  opacity: 0;
  transition: all 300ms linear;
}

/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-reverse-enter {
  transform: translateX(100vw);
  opacity: 0;
  position: absolute;
}

/* Declare transition properties */
.slide-reverse-enter.slide-reverse-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-reverse-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-reverse-exit.slide-reverse-exit-active {
  transform: translateX(-100vw);
  opacity: 0;
  transition: all 300ms linear;
}

/*
  Note: Only ant-design overrides should live here.

  Styles should be scoped within the .js files they
  correspond to whenever possible.

  If media queries are the *only* way to handle a styling
  issue, then that .css file should be colocated with the
  component.

  A good alternative to media queries is to leverage
  the react-breakpoints and render things conditionally.
*/

/* Clean up dropdown menu item groups */
.ant-dropdown-menu-item-group {
  line-height: initial;
}

.ant-dropdown-menu-item-group
  .ant-dropdown-menu-item-group-list
  li.ant-dropdown-menu-item {
  list-style-type: none;
}

/* Don't show No Data Placeholder in lists */
.ant-list-empty-text {
  display: none;
}

/* Make card actions block-clickable */
.ant-card-actions li {
  margin: 0;
}

.ant-card-actions li * {
  width: 100%;
}

.ant-card-actions li button {
  padding: 12px 0 !important;
}

.ant-card-actions li a {
  color: #1890ff !important;
  padding: 12px 0;
}

/* Hide pagination*/
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-item {
  display: none;
}

/* Don't allow other styles (from email previews) to override menu styles */
.ant-menu-item a {
  color: #ffffff !important;
  text-decoration: inherit !important;
}

.ant-dropdown-menu-item a {
  color: inherit !important;
  text-decoration: inherit !important;
}

/* Make the table sorting carets bigger */
.ant-table-column-sorter .anticon-caret-up,
.ant-table-column-sorter .anticon-caret-down {
  font-size: 18px !important;
  line-height: 9px !important;
}

.ant-table-column-sorter .anticon-caret-up {
  margin-top: -4px !important;
}

.ant-table-column-sorter .anticon-caret-down {
  margin-bottom: -4px !important;
}

/* Move the drawer trigger to the top of the page */
.ant-layout-sider-zero-width-trigger {
  top: 16px !important;
}

/* Make the drawer content scrollable without breaking the trigger */
.ant-layout-sider-children {
  overflow: auto;
}

/* Prevent antd tables from wrapping text in a cell */
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  word-break: keep-all !important;
}

/* Allow for x-scrolling tables with fixed left columns */
.ant-table td {
  white-space: nowrap;
}

/* Force the first column to wrap */
.wrap-first-column .ant-table-row td:first-child {
  white-space: initial !important;
}
.wrap-first-column .ant-table-thead th:first-child {
  white-space: initial !important;
}

/* Wrap a particular column */
.ant-table-row td.wrap-this-column {
  white-space: initial !important;
}
.ant-table-thead th.wrap-this-column {
  white-space: initial !important;
}

/* Word break a particular column */
.ant-table-row td.break-this-column {
  word-break: break-word !important;
}
.ant-table-thead th.break-this-column {
  word-break: break-word !important;
}

/* Clean up form presentation */
.ant-form-item-label {
  white-space: normal !important;
  text-align: left !important;
  line-height: inherit !important;
  margin-bottom: 4px !important;
}
.ant-radio-wrapper span:not(.ant-radio) {
  white-space: normal !important;
  display: inline-block !important;
  vertical-align: top !important;
}
.ant-checkbox-wrapper > span:not(.ant-checkbox) {
  white-space: normal !important;
  display: inline-block !important;
  vertical-align: top !important;
  width: calc(100% - 16px);
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.ant-form-item .ant-checkbox-group label {
  margin-bottom: 8px;
  white-space: nowrap !important;
}
.ant-form-item .ant-radio-group label {
  margin-bottom: 8px;
  white-space: nowrap !important;
}

/* Don't change size between label and input on mobile */
.ant-form-item-label {
  padding: 0 !important;
}

/* Shrink the padding in the navbar */
.ant-layout-header {
  padding: 0 16px !important;
}

/* Allow card titles to wrap */
.ant-card-head-title {
  white-space: normal !important;
}

/* Make dropdown arrows more visible */
.ant-select-arrow {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
}

/* Hide month/year select on calendars */
.ant-fullcalendar-header > .ant-radio-group {
  display: none;
}

/* Highlight today */
@media screen and (min-width: 577px) {
  .ant-fullcalendar-today .ant-fullcalendar-date {
    border: 1px solid #1890ff;
    background: #e6f7ff !important;
  }
}

/* Don't highlight selected day */
.ant-fullcalendar-fullscreen
  .ant-fullcalendar-selected-day
  .ant-fullcalendar-date {
  background: inherit;
}
.ant-fullcalendar-fullscreen
  .ant-fullcalendar-selected-day
  .ant-fullcalendar-date
  .ant-fullcalendar-value {
  color: inherit;
}

/* Allow larger dropdown for month selection in calendars */
.ant-fullcalendar-header .ant-select-dropdown-menu {
  max-height: calc(100vh - 60px);
}

button.ant-btn-primary {
  background-color: #1677ff;
}

/* Allow full screen modals */
.full-screen-modal {
  top: 0;
  margin: 0;
  width: 100vw !important;
  height: 100vh !important;
  overflow: auto;
}

.ant-tooltip-inner {
  word-break: keep-all !important;
}

.active-tab {
  color: #0078db;
  border-bottom: 2px solid #0078db;
}
.editor-w {
  width: -webkit-fill-available !important;
}
.ck.ck-editor {
  width: -webkit-fill-available !important;
}
a {
  color: blue;
}
a:hover {
  color: blueviolet;
  cursor: pointer;
}

ol {
  list-style-type: style;
  margin: 4px;
  padding: 0px 12px;
}
ul {
  list-style-type: inherit;
  margin: 4px;
  padding: 0px 12px;
}
.ck-content .image.image_resized img {
  width: 600px;
}

.home-template-container {
  min-height: calc(100vh - 60px);
}
.module-layout-container {
  height: calc(100vh - 60px);
}

/* Pagination */
.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
  margin-top: 6px;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #007bff;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: #cccccc;
}

.page-item.active .page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

/*Load more*/
.loadingtext p {
  color: gray;
  position: relative;
  font-size: large;
  font-weight: bold;
}

.loadingtext p::after {
  position: absolute;
  animation: b 3s infinite linear;
  color: gray;
  content: "";
  left: 105%;
}

@keyframes b {
  0% {
    content: "";
  }

  10% {
    content: ".";
  }

  40% {
    content: "..";
  }

  70% {
    content: "...";
  }

  100% {
    content: "";
  }
}

.des-container img,
.des-container figure img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

figure .image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-figure {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-style-alignLeft {
  float: left;
  margin-right: 1em;
}

.image-style-alignCenter {
  display: block;
  margin: 0 auto;
}

.image-style-alignRight {
  float: right;
  margin-left: 1em;
}

figure.image {
  display: block;
  margin: 0 auto;
  text-align: center;
}
